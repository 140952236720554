import Heading from "../Heading";
import { useNavigate } from "react-router-dom";
import { hasAdmin } from "../../services/menu";
import { useEffect, useState } from "react";
import { doGet } from "../../services/rest";
import MySpinner from "../MySpinner";

function AwardsAdmin() {
    const navigate = useNavigate();
    const isAwardAdmin = hasAdmin("ROLE_AWARD_ADMIN");
    const [loading, setLoading] = useState(true);
    const [awards, setAwards] = useState([]);

    useEffect(() => {
        if (!isAwardAdmin) {
            navigate("/");
        }
    }, [isAwardAdmin, navigate]);

    useEffect(() => {
        doGet('awards-admin/list').then(resp => {
            setAwards(resp);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <>
                <Heading title={"Awards"} />
                <MySpinner />
            </>
        );
    }

    return (
        <>
            <Heading title={"Awards"} />
            <div className={'container'}>
                <div className={'button-container flex-space-between'}>
                    <button className={'add-award-button'} onClick={() => navigate('/award-admin/form')}>Add Award
                    </button>
                    <button className={'add-award-button'} onClick={() => navigate('/award-admin/upload')}>Upload Awards
                    </button>
                </div>
                <h3 className="section-title">Current Awards</h3>
                <div className="awards-list">
                    {awards.map(award => (
                        <div
                            key={award.id}
                            className="award-item"
                            onClick={() => navigate(`/award-admin/form/${award.id}`)}
                        >
                            {award.title}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default AwardsAdmin;
