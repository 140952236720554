import TeamMemberImages from "./TeamMemberImages";
import {defaultColor} from "../../defaults/AppConfig";
import DirectoryAwardsView from "./DirectoryAwardsView";

function ViewEmployee({employee}) {

    return(<>
        <div>
            <TeamMemberImages teamMember={employee} />
        </div>
        <div style={style.name}>{employee.fname} {employee.lname}</div>
        <div style={{textAlign: "left", width: '500px'}}>
            <div style={style.paddingTop}><span style={style.textBold}>Department: </span>
                <span>{employee.teamDepartments.name}</span></div>
            <div style={style.paddingTop}><span style={style.textBold}>Position: </span>
                <span>{employee.position}</span></div>
            {employee.phone && (
                <div style={style.paddingTop}><span style={style.textBold}>Phone: </span><span><a
                    style={style.linkStyle} href={`tel:${employee.phone}`}>{employee.phone} {employee.extension && `Ext. ${employee.extension}`}</a> </span></div>
            )}
            {employee.email && (
                <div style={style.paddingTop}><span style={style.textBold}>Email: </span><span><a
                    style={style.linkStyle} href={`mailto:${employee.email}`}>{employee.email}</a> </span></div>
            )}
            {employee.mobile && (
                <div style={style.paddingTop}><span style={style.textBold}>Cell: </span><span><a
                    style={style.linkStyle} href={`tel:${employee.mobile}`}>{employee.mobile}</a> </span></div>
            )}
        </div>
        <DirectoryAwardsView gscId={employee.employeeID} />
    </>)
}

const style = {
    divPadding: {
        paddingTop: '10px',
    },
    defaultContainer: {
        alignItems: 'center',
    },
    imageContainer: {
        width: '80%',
        flexDirection: 'row',
    },
    name: {
        color: defaultColor,
        fontWeight: 'bold',
        fontSize: 26,
    },
    informationContainer: {
        width: '80%',
        paddingTop: 10,
        textAlign: 'left'
    },
    multiText: {
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
    },
    textBold: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textNotBold: {
        fontSize: 16,
    },
    elopementAlertContainer: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    paddingTop: {
        paddingTop: 10,
    },
    linkStyle: {
        textDecoration: 'none',
        color: 'inherit'
    }
};
export default ViewEmployee
