import {useEffect, useState} from "react";
import {doGet, doPostBody} from "../../services/rest";

function AddAwardFromDirectory({gscId, onComplete}) {
    const [showNoIdError, setShowNoIdError] = useState(false)
    const [user, setUser] = useState({})
    const [awards, setAwards] = useState([])
    const [processResponse, setProcessResponse] = useState();
    useEffect(() => {
        if(gscId){
            doGet(`awards-admin/gsc-user/${gscId}`).then(data => {
                if(data){
                    setUser(data)
                }else{
                    setShowNoIdError(true)
                }
            })
            doGet('awards-admin/list').then(data => {
                setAwards(data);
            })
        }
    }, [gscId])

    const onAwardClick = async (award) => {
        if(window.confirm(`Are you sure you want to give this award to ${user.displayName}?`)){
            const awardData = {
                user: user,
                award: award,
                status: 'success',
                type: 'add'
            }
            const resp = await doPostBody(`awards-admin/submit-awards`, [awardData])
            setProcessResponse(resp)
        }
    }

    if(showNoIdError){
        return <div>No user found with that GSC ID</div>
    }

    if(processResponse){
        return (<div className={'inner-container'}>
            <h4>Processed results</h4>
            <table className={'workorder'} style={{width: '100%', fontSize: 12}}>
                <tr>
                    <th>Name/ Employee ID</th>
                    <th>Action</th>
                    <th>Award</th>
                </tr>
                {processResponse.results.map(item => <tr key={item.id}>
                    <td>{item.user.displayName}</td>
                    <td>{item.status}</td>
                    <td>{item.award.title}</td>
                </tr>)}
            </table>
            <button onClick={onComplete} style={{marginTop: 10, zIndex: 10000}}>Done</button>
        </div>)
    }


    return (
        <div className={'inner-container'}>
            <h4>Click award to add to user</h4>
            <div className="awards-list">
                {awards.map(award => (
                    <div
                        key={award.id}
                        className="award-item"
                        onClick={() => onAwardClick(award)}
                    >
                        {award.title}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AddAwardFromDirectory;
