import {useState, useEffect} from "react";
import MySpinner from "../MySpinner";

function AwardView({award}) {
    const [orientation, setOrientation] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.src = 'https://gsc.app/fb/.uploads/'+award.image;
        img.onload = () => {
            const isPortrait = img.height > img.width;
            setOrientation(isPortrait ? 'portrait' : 'landscape');
        };
    }, [award.image]);

    if (!orientation) {
        return <MySpinner/>
    }

    if(orientation === 'landscape') {
        return       <div className={'post'} style={{width: 800}}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}>
                <div>
                    <img src={'https://gsc.app/fb/.uploads/' + award.image} alt={award.name} style={{
                        objectFit: 'contain'
                    }}/>
                    <div>+{award.points} points</div>
                </div>
                <div>
                <h4>{award.title}</h4>
                    <div className={'reset'} dangerouslySetInnerHTML={{__html: award.message}}></div>
                </div>
            </div></div>
            }

            return (
            <div className={'post'} style={{width: 800}}>

            <h4>{award.title}</h4>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <div style={{flex: 1}}>
                    <img src={'https://gsc.app/fb/.uploads/' + award.image} alt={award.name} style={{
                        objectFit: 'contain',
                        maxWidth: 400,
                    }}/>
                    <div>+{award.points} points</div>
                </div>
                <div style={{flex: 1.5, paddingLeft: 20}}>
                    <div className={'reset'} dangerouslySetInnerHTML={{__html: award.message}}></div>
                </div>
            </div>
        </div>
    )
}

export default AwardView;
