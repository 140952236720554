import {useState} from "react";
import {doGet, doPost, doPostBody} from "../../services/rest";
import MyCheckBox from "../MyCheckBox";

import MySpinner from "../MySpinner";

function SearchForUser({award, onCompleted}){
    const [searchResults, setSearchResults] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [processResponse, setProcessResponse] = useState();

    const search = event => {
        if(event.target.value.length > 3){
            doGet(`awards-admin/search-for-users?search=${event.target.value}`).then(result => {setSearchResults(result)})
        }
    }

    const isChecked = (id) => {
        return selectedUsers.some(item => item.id === id)
    }

    const onUserChecked = (user) => {
        if(selectedUsers.some(item => item.id === user.id)){
            setSelectedUsers(selectedUsers.filter(item => item.id !== user.id))
        }else{
            setSelectedUsers([...selectedUsers, user])
        }
    }

    const addAward = async () => {
        if(selectedUsers.length > 0){
            setLoading(true)
            const awardData = []
            for(const user of selectedUsers){
                awardData.push({
                    user: user,
                    award: award,
                    status: 'success',
                    type: 'add'
                })
            }
            const resp = await doPostBody(`awards-admin/submit-awards`, awardData)
           setProcessResponse(resp)
            setLoading(false)
        }else{
            window.alert("Please select at least one user to award.");
        }
    }

    if(loading){
        return (<>
            <div className={'inner-container'}>
                <MySpinner />
            </div>
        </>)
    }

    if(processResponse){
        return (<div className={'inner-container'}>
            <h4>Processed results</h4>
            <table className={'workorder'} style={{width: '100%', fontSize: 12}}>
                <tr>
                    <th>Name/ Employee ID</th>
                    <th>Action</th>
                    <th>Award</th>
                </tr>
                {processResponse.results.map(item => <tr key={item.id}>
                    <td>{item.user.displayName}</td>
                    <td>{item.status}</td>
                    <td>{item.award.title}</td>
                </tr>)}
            </table>
            <button onClick={onCompleted} style={{marginTop: 10}}>Done</button>
        </div>)
    }

    return (<div className={'inner-container'}>
        <input type={'text'} className={'search-input'} placeholder={'type to search'} onKeyUp={search}/>
        <div style={{display: 'flex'}}>
            {selectedUsers.map(user => (
                <div style={{margin: 5, backgroundColor: '#E8E8E8', borderRadius: 30, padding: 5}}
                     key={user.id}>{user.displayName}</div>))}
        </div>
        {searchResults.map(user => (
            <MyCheckBox name={user.id} id={user.id} label={`${user.displayName} (${user.gscId})`}
                        checked={isChecked(user.id)} onChange={() => onUserChecked(user)}/>))}

        {selectedUsers.length > 0 && <button onClick={addAward} className={'submitPost'}>Add Award</button>}
    </div>)
}

export default SearchForUser;
