import Heading from "../Heading";
import {useEffect, useState} from "react";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";

function MyAwards(){

    const [loading, setLoading] = useState(true)
    const [awards, setAwards] = useState([])
    const [points, setPoints] = useState(0)

    useEffect(() => {
        doGet('awards/mine').then(data => {
            setAwards(data)
            let points = 0;
            data.forEach(award => {points += award.points})
            setPoints(points);
            setLoading(false)
        })
    },[])

    if(loading) {
        return <>
            <Heading title={'My Awards'} />
            <MySpinner />
        </>
    }

    return (<>
        <Heading title={'My Awards'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {/* Display the number of awards and total points */}
                <div style={{marginBottom: '20px', textAlign: 'center'}}>
                    <h3>Total Awards: {awards.length}</h3>
                    <h4>Total Points: {points} points</h4>
                </div>

                {/* Display each award */}
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px'}}>
                    {awards.map((award, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                padding: '10px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    maxWidth: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        maxWidth: '400px',
                                        height: 'auto',
                                    }}
                                >
                                    <img
                                        src={`https://gsc.app/fb/.uploads/${award.image}`}
                                        alt={award.title}
                                        className={'award-image-large'}
                                    />
                                </div>
                                <div style={{textAlign: 'center', marginTop: '10px'}}>{award.points} points</div>
                            </div>
                            <div style={{flex: 1.5, paddingLeft: '20px', maxWidth: '100%'}}>
                                <h4>{award.title}</h4>
                                <div dangerouslySetInnerHTML={{__html: award.message}}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </div>
        </>
        )
        }
        export default MyAwards;
