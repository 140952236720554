import Heading from "../Heading";
import {useDropzone} from "react-dropzone";
import {useCallback, useMemo, useState} from "react";
import {doPost, doPostBody} from "../../services/rest";
import MySpinner from "../MySpinner";
import {useNavigate} from "react-router-dom";


function AwardUpload(){

    const navigate = useNavigate();

    const [results, setResults] = useState();
    const [loading, setLoading] = useState(false);
    const [processResponse, setProcessResponse] = useState();


    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData();
        formData.set('file', acceptedFiles[0]);
        setLoading(true)
        doPost('awards-admin/upload-template', formData).then(data => {
            setResults(data);
            setLoading(false);
        }
        )
    }, [])


    const {getRootProps, getInputProps, isDragActive,isFocused,
        isDragAccept,
        isDragReject} = useDropzone({ accept:{
            'text/csv': ['.csv']
        },onDrop})

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const submitAwards = async () =>{
        const filtered = results.results.filter(item => item.status === 'success');
        setLoading(true)
        const resp = await doPostBody('awards-admin/submit-awards', filtered)
        setLoading(false)
        setResults(undefined)
        setProcessResponse(resp);
    }

    if(loading){
        return(<>
            <Heading title={'Upload Award Template'} />
            <MySpinner />
        </>)
    }

    if(processResponse){
        return (<>
            <Heading title={'Upload Award Template'} />
                <div className={'inner-container'} style={{marginTop: 50}}>
                    <h4>Processed results</h4>
                    <table className={'workorder'} style={{width: '100%', fontSize: 12}}>
                        <tr>
                            <th>Name/ Employee ID</th>
                            <th>Action</th>
                            <th>Award</th>
                        </tr>
                        {processResponse.results.map(item => <tr key={item.id}>
                            <td>{item.user.displayName}</td>
                            <td>{item.status}</td>
                            <td>{item.award.title}</td>
                        </tr>)}
                    </table>
                    <button style={{marginTop: 10}} onClick={() => navigate("/award-admin")}>Done</button>
                </div>
            </>
        )
    }

    if (results) {
        return (<>
            <Heading title={'Upload Award Template'} />
            <div className={'inner-container'} style={{marginTop: 50}}>
                <h4>Processed results</h4>
                {results.status === 'success' ?<table className={'workorder'} style={{width:'100%', fontSize: 12}}>
                    <tr><th>Name/ Employee ID</th><th>Action</th><th>Status</th></tr>
                    {results.results.map(item =>{
                        if(item.status === 'success'){
                            return <tr key={item.id}><td>{item.user.displayName}</td><td>{item.type}</td><td>Will be {item.type.toLowerCase() === 'add' ? 'added to' : 'removed from'} award {item.award.title}</td></tr>
                        }
                        if(item.status === 'warning'){
                            return <tr style={{backgroundColor: 'rgb(255, 152, 0)'}} key={item.id}><td>{item.user.displayName}</td><td>{item.type}</td><td>{item.reason}</td></tr>
                        }
                        return <tr style={{backgroundColor: 'rgb(244, 67, 54)'}} key={item.id}><td>{item?.user?.displayName ?? item.employeeId}</td><td></td><td>{item.reason}</td></tr>
                    }
                    )}
                </table>: <div>Status: failed <br/> Reason: {results.reason}</div>}
                <div className={'flex-space-between'} style={{paddingTop: 10}}>
                    <button onClick={() => setResults(undefined)}>Cancel</button>
                    <p>Anything in red or orange will be ignored</p>
                    <button onClick={submitAwards}>Continue</button>
                </div>
            </div>
                        </>)
                    }


                    return(<>
                    <Heading title={'Upload Award Template'}/>
                    <div className={'inner-container'} style={{paddingTop: 50}}>
                        <div style={{padding: 50}}>Download Template</div>
                        <div className="container">
                            <div {...getRootProps()}>
                                <input {...getInputProps({style})} />
                                {isDragActive ? <p>Drop file here...</p> :
                                    <p>Drag and drop your file, or click to select files.</p>
                                }
                            </div>
                        </div>
                    </div>
                </>
                    )
                    }

                    export default AwardUpload;
