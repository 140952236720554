import {useEffect, useState} from "react";
import {doPost} from "../../services/rest";

function VendorDocumentForm({document, vendorId, onCompleted}) {

    const [documentData, setDocumentData] = useState({});

    const [file, setFile] = useState(null);


    useEffect(() => {
        if(document){
            setDocumentData(document);
        }
    },[document])

    const onChange = e => {
        setDocumentData({...documentData, [e.target.name]: e.target.value})
    }

    const saveDocument = async () => {
        if(!documentData.title){
            window.alert("Please enter a title for the document");
            return;
        }
        if(!documentData.documentType){
            window.alert("Please select a document type");
            return;
        }
        if(!file){
            window.alert("Please select a document");
            return;
        }
        const formData = new FormData();
        formData.append('title', documentData.title);
        formData.append('documentType', documentData.documentType);
        if(documentData.expirationDate){
            formData.append("expirationDate", documentData.expirationDate)
        }
        formData.append('file', file);
        formData.append('vendor', vendorId);
        const response = await doPost('vendor-admin/upload-document', formData);
        onCompleted(response);
    }

    return (<div className={'inner-container'}>
        <div className={'flex'}>
            <label style={{width: 200, textAlign: "left"}}>Document Title: </label>
            <input style={{flex: 1}} onChange={onChange} type={'text'} name={'title'} className={''}
                   value={documentData.title}/>
        </div>
        <div className={'flex'} style={{marginTop: 10}}>
            <label style={{width: 200, textAlign: "left"}}>Expiration Date: </label>
            <input style={{flex: 1}} onChange={onChange} type={'date'} name={'expirationDate'} className={''}
                   value={documentData.expirationDate}/>
        </div>
        <div className={'flex'} style={{marginTop: 10}}>
            <label style={{width: 200, textAlign: "left"}}>Document Type: </label>
            <select style={{flex: 1}} onChange={onChange} name={'documentType'} value={documentData.documentType}>
                <option></option>
                <option value={'W9'} selected={documentData?.documentType === 'W9'}>W-9</option>
                <option value={'POI'} selected={documentData?.documentType === 'POI'}>Proof of Insurance</option>
                <option value={'BA'} selected={documentData?.documentType === 'BA'}>Business Agreement</option>
                <option value={'EX'} selected={documentData?.documentType === 'EX'}>Exclusions</option>
            </select>
        </div>
        <div className={'flex'} style={{marginTop: 10}}>
            <label style={{width: 200, textAlign: "left"}}>Document: </label>
            <input style={{flex: 1}} onChange={e => setFile(e.target.files[0])} type={'file'} name={'document'} className={''}
                   value={documentData.document}/>
        </div>
        <button onClick={saveDocument} style={{marginTop: 15}} className={'submitPost'}>Save</button>
    </div>)
}

export default VendorDocumentForm;
