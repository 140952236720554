import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faTrash} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import SearchForUser from "./SearchForUser";

function AwardUserAdmin(){
    const {id} = useParams()
    const [awardInfo, setAwardInfo] = useState({})
    const [loading, setLoading] = useState(true)
    const [addUser, setAddUser] = useState(false)
    const [award, setAward] = useState()

    useEffect(()=>{
       loadAwards();
    }, [id])

    const loadAwards = () =>{
        doGet(`awards-admin/award-info/${id}`).then(data => {
            setLoading(false);
            setAwardInfo(data)
            setAward(data.award);
        });
    }

    const removeAward = (userId) => {
        if(window.confirm("Are you sure you want to remove this award?")){
            setLoading(true);
            doGet(`awards-admin/delete-award/${userId}/${id}`).then(data => {
                setLoading(false);
                setAwardInfo(data)
            });
        }
    }

    if(loading){
        return(<>
            <Heading />
            <MySpinner />
        </>)
    }

    return (<>
        <Heading title={awardInfo.award.title} icons={[<FontAwesomeIcon onClick={() => setAddUser(true)} cursor={'pointer'}  icon={faAdd} />]}/>
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {awardInfo.users.map(user => (
                <div style={{display:"flex", paddingBottom: 10}}><div style={{flex: 1}}>{user.displayName}</div><div style={{flex: 1}}> <FontAwesomeIcon onClick={() => removeAward(user.id)} cursor={'pointer'} icon={faTrash} /></div></div>
            ))}
        </div>
        {addUser && <PopUp
            handleClose={() => setAddUser(false)}
            content={<SearchForUser award={award} onCompleted={() => {
                setAddUser(false);
                setLoading(true);
                loadAwards();
            }} />}
        />}
    </>)
}
export default AwardUserAdmin;
