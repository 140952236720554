import {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import {hasAdmin} from "../../services/menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import AddAwardFromDirectory from "./AddAwardFromDirectory";

function DirectoryAwardsView({gscId}) {
    const [awards, setAwards] = useState([]);
    const awardsAdmin = hasAdmin("ROLE_AWARD_ADMIN")
    const [showAdd, setShowAdd] = useState(false)
    useEffect(() => {
        loadAwards()
    },[gscId])

    const loadAwards = () =>{
        doGet(`awards/user/${gscId}`).then(data => setAwards(data))
    }
    if(!awards){
        return null;
    }
    const completeAddAward = () =>{
        setShowAdd(false);
        loadAwards();
    }
    return (<>
             <div className={'flex'} style={{alignItems: "center", justifyContent: "center"}}>
                {awards.map(award => <div style={{padding: 10}} key={award.id}><img className={'award-image-small'}
                                                              src={`https://gsc.app/fb/.uploads/${award.image}`}/></div>)}
                {awardsAdmin && <FontAwesomeIcon icon={faAdd} cursor={'pointer'} style={{ padding: 10}} onClick={() => setShowAdd(true)} />}
            </div>
            {showAdd && <PopUp
                handleClose={() => setShowAdd(false)}
                content={<AddAwardFromDirectory gscId={gscId} onComplete={completeAddAward} />}

            />}
        </>
    )
}

export default DirectoryAwardsView;
