import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet, doPostBody, downloadFileFromUrlGet} from "../../services/rest";
import Heading from "../Heading";
import {hasAdmin} from "../../services/menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faTrash} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import VendorDocumentForm from "./VendorDocumentForm";
import FileSaver from 'file-saver';
import MySpinner from "../MySpinner";
import moment from "moment";

function VendorForm() {

    const {id} = useParams();

    const [vendor, setVendor] = useState({});
    const vendorAdmin =  hasAdmin("ROLE_VENDORS_ADMIN")
    const [viewForm, setViewForm] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(id){
            setLoading(true);
            const getVendor = async () => {
                const resp = await doGet(`vendor/${id}`)
                setVendor(resp);
                setLoading(false);
            }
            getVendor();
        }
    }, [id])

    const onChange = e => {
        setVendor({...vendor, [e.target.name]: e.target.value})
    }

    const getDocumentType = (doc) =>{
        switch (doc){
            case 'W9':
                return 'W-9';
            case 'POI':
                return 'Proof of Insurance';
            case 'BA':
                return 'Business Agreement';
            case "EX":
                return "Exclusions"
            default:
                return 'Unknown';
        }
    }

    const deleteDocument = async (documentId) => {
        if(window.confirm("Are you sure you want to delete this document?")){
            const response = await doGet(`vendor-admin/delete-document/${documentId}`)
            setVendor(response);
        }
    }

    const openFile = async (id, name) =>{
        const file = await downloadFileFromUrlGet(`vendor/view-document/${id}`);
        FileSaver.saveAs(new Blob([file]), name);
    }

    const save = async () => {
        if(!vendor.vendorName){
            window.alert("Please enter a vendor name");
            return;
        }

        setLoading(true);
        const resp = await doPostBody('vendor/save', vendor);

        setVendor(resp);
        setLoading(false);
    }

    if(loading){
        return(<>
            <Heading />
            <MySpinner />
        </>)
    }

    return <>
        <Heading title={vendor.id ? `Edit Vendor ${vendor.id}` : "Add Vendor"}/>
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div className={'flex'}>
                <label style={{width: 200, textAlign: "left"}}>Vendor Name: </label>
                <input style={{flex: 1}} onChange={onChange} type={'text'} name={'vendorName'} className={''}
                       value={vendor.vendorName}/>
            </div>
            <div className={'flex'} style={{paddingTop: 10}}>
                <label style={{width: 200, textAlign: 'left'}}>Address: </label>
                <input style={{flex: 1}} onChange={onChange} type={'text'} name={'address'} className={''}
                       value={vendor.address}/>
            </div>
            <div className={'flex'} style={{paddingTop: 10}}>
                <label style={{width: 200, textAlign: 'left'}}>City: </label>
                <input style={{flex: 1}} onChange={onChange} type={'text'} name={'city'} className={''}
                       value={vendor.city}/>
            </div>
            <div className={'flex'} style={{paddingTop: 10}}>
                <label style={{width: 200, textAlign: 'left'}}>State: </label>
                <input style={{flex: 1}} onChange={onChange} type={'text'} name={'state'} className={''}
                       value={vendor.state}/>
            </div>
            <div className={'flex'} style={{paddingTop: 10}}>
                <label style={{width: 200, textAlign: 'left'}}>TIN: </label>
                <input style={{flex: 1}} onChange={onChange} type={'text'} name={'tin'} className={''}
                       value={vendor.tin}/>
            </div>
            <div style={{paddingTop: 10}}>
                <label>Notes</label>
                <textarea onChange={onChange} name={'notes'} value={vendor.notes} className={'notes'}></textarea>
            </div>
            {vendorAdmin ? <div className={'flex'} style={{paddingTop: 10}}>
                <label style={{width: 200, textAlign: 'left'}}>Status: </label>
                <div style={{display: "flex", flex: 1}}>
                    <div style={{flex: 1}}>Approved: <input type={'radio'} name={'vendorStatus'} value={'APPROVED'} onChange={onChange} checked={vendor.vendorStatus === 'APPROVED'}/></div>
                    <div style={{flex: 1}}>Rejected: <input type={'radio'} name={'vendorStatus'} value={'REJECTED'} onChange={onChange} checked={vendor.vendorStatus === 'REJECTED'}/></div>
                </div>
            </div> : <div className={'flex'} style={{paddingTop: 10}}>
                <label style={{width: 200, textAlign: 'left'}}>Status: {vendor.vendorStatus ? vendor.vendorStatus : ''}</label>
                <div style={{flex: 1}}/>
            </div>}
            <div className={'flex'} style={{alignContent: 'center'}}><div style={{padding: 5}}>Documents</div>{vendorAdmin && <FontAwesomeIcon onClick={() => setViewForm(true)} style={{padding: 5}} cursor={'pointer'} icon={faAdd} />}</div>
            {vendor?.vendorDocumentsList?.map(document => (<div className={'flex'} style={{paddingTop: 10}}>
                <div style={{flex: 1, textAlign: 'left'}}>Title: {document.title}</div>
                <div style={{flex: 1}}>Document Type: {getDocumentType(document.documentType)}</div>
                <div style={{flex: 1}}>{document.expirationDate && <>Expiration Date: {moment(document.expirationDate).format('MM/DD/yyyy')}</>}</div>
                <div onClick={() => openFile(document.id, document.originalName)}
                     style={{textAlign: "right", cursor: 'pointer'}}>View
                </div>
                {vendorAdmin && <FontAwesomeIcon icon={faTrash} cursor={'pointer'} style={{paddingLeft: 5}}
                                                 onClick={() => deleteDocument(document.id)}/>}
            </div>))}
            {(vendorAdmin || !vendor.id) && <button onClick={save} style={{marginTop: 20}} className={'submitPost'}>Save</button>}
        </div>
        {viewForm && <PopUp
            content={<VendorDocumentForm vendorId={vendor?.id} onCompleted={(resp) => {
                setViewForm(false);
                setVendor(resp);
            }} />}
            handleClose={() => setViewForm(false)}
            />}
    </>

}

export default VendorForm;
