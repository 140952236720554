import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Heading from "../Heading";
import {Editor} from "@tinymce/tinymce-react";
import {doGet, doPost, doPostBody, imageUploadHandler} from "../../services/rest";
import {hasAdmin} from "../../services/menu";
import MySpinner from "../MySpinner";
import PopUp from "../PopUp";
import AwardView from "./AwardView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons";

function AwardForm(){
    const {id} = useParams();
    const [award, setAward] = useState({})
    const navigate = useNavigate();
    const isAwardAdmin = hasAdmin("ROLE_AWARD_ADMIN")
    const [loading, setLoading] = useState(false)
    const [displayPreview, setDisplayPreview] = useState(false)

    useEffect(()=>{
        if(!isAwardAdmin){
            navigate("/")
        }
    },[isAwardAdmin, navigate])

    useEffect(()=>{
        if(id){
            setLoading(true)
            doGet(`awards/${id}`).then(res => {
                setAward(res);
                setLoading(false);
            })
        }
    }, [id])

    const onUpdate = e =>{
        setAward({...award, [e.target.name]: e.target.value})
    }
    const onEditChange = event => {
        setAward(prevWorkOrder => ({
            ...prevWorkOrder,  // copy all the properties from the old state
            message: event      // overwrite the value of the field we are interested in
        }));
    }

    const uploadFile = async (file) => {
        if(file){
            if (!file.type.startsWith('image/')) {
                window.alert("Please upload a valid image file.");
                return;
            }
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.onload = async () => {
                    // Check if the image is square
                    if (img.width !== img.height) {
                        window.alert("The image must be square (equal width and height).");
                        return;
                    }

                    console.log(img.width, img.height)

                    // Check if the image is HD (e.g., at least 1080x1080)
                    if (img.width < 1080 || img.height < 1080) {
                        window.alert("The image must have a resolution of at least 1080x1080.");
                        return;
                    }

                    // Proceed with upload if validation passes
                    const formData = new FormData();
                    formData.append('file', file);
                    const resp = await doPost('awards-admin/upload-file', formData);
                    if (resp.file) {
                        setAward((prevAward) => ({
                            ...prevAward,
                            image: resp.file
                        }));
                    } else {
                        window.alert("Something has gone wrong. We could not upload your file.");
                    }
                };
                img.onerror = () => {
                    window.alert("Could not load the image. Please try another file.");
                };
                img.src = e.target.result; // Set the image source
            };
            reader.readAsDataURL(file);
        }
    }

    const saveForm = async () =>{
        if(!award.title){
            window.alert("Please enter a title");
            return;
        }
        if(!award.points){
            window.alert("Please enter a points value");
            return;
        }
        if(!award.message){
            window.alert("Please enter a message");
            return;
        }
        if(!award.image){
            window.alert("Please upload an image");
            return;
        }
        setLoading(true);
        const resp = await doPostBody('awards-admin/save', award)
        setAward(resp);
        setLoading(false);
    }

    if(loading){
        return(<>
            <Heading title={award.id ? "Edit Award" : "Add Award"} />
            <MySpinner />
        </>)
    }

    return (<>
        <Heading title={award.id ? "Edit Award" : "Add Award"} icons={award.id ? [<FontAwesomeIcon cursor={'pointer'} onClick={() => navigate(`/award-admin/user/${award.id}`)} icon={faUserEdit} />] : []} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div className={'form-container'}>
                <div className={'form-inner-container-text'}>Award Title:</div>
                <div className={'form-inner-container'}><input type={'text'} className={'search-input'}
                                                               placeholder={'Enter Award Title'} value={award.title}
                                                               name={'title'} onChange={onUpdate}/></div>
            </div>
            <div className={'form-container'}>
                <div className={'form-inner-container-text'}>Message:</div>
                <div className={'form-inner-container'}><Editor
                    onEditorChange={(e) => onEditChange(e)}
                    licenseKey={'gpl'}
                    value={award.message}
                    init={{
                        height: 250,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                        ],
                        toolbar: 'undo redo | blocks | link table|' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | image | bullist numlist outdent indent | ' +
                            'removeformat | toggle',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        automatic_uploads: true,
                        images_upload_handler: imageUploadHandler,
                    }}
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                /></div>
            </div>
            <div className={'form-container'}>
                <div className={'form-inner-container-text'}>Points:</div>
                <div className={'form-inner-container'}><input type={'text'} className={'search-input'}
                                                               placeholder={'Enter Award Point Value'}
                                                               value={award.points}
                                                               name={'points'} onChange={onUpdate}/></div>
            </div>
            <div className={'form-container'}>
                <div className={'form-inner-container-text'}>Image:</div>
                <div className={'form-inner-container'}>{award.image ? <img width={300} className={'award-image-large'} src={`https://gsc.app/fb/.uploads/${award.image}`} /> : <input type={'file'} accept={"image/png, image/gif, image/jpeg"} className={'search-input'} onChange={e => uploadFile(e.target.files[0])}/>}</div>
            </div>
            <div className={'flex'}>
                <button style={{margin: 20}} onClick={saveForm}>Save</button>
                <button style={{margin: 20}} onClick={() => setDisplayPreview(true)}>Preview Award</button>
            </div>
        </div>
        {displayPreview && <PopUp
            content={<AwardView award={award} /> }
            handleClose={() => setDisplayPreview(false)}
        />}
    </>)
}

export default AwardForm;
